<template>
    <layout class="wrapper">
        <!-- 面包屑 -->
        <div class="container mt15 flex x-left flex-wrap">
            <div class="color999">您所在的位置：</div>
            <a-breadcrumb>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/'}">首页</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:'/journal-article'}">期刊论文</router-link>
                </a-breadcrumb-item>
                <a-breadcrumb-item>
                    <router-link class="color999" :to="{path:''}">期刊约稿</router-link>
                </a-breadcrumb-item>
            </a-breadcrumb>
        </div>
        <div class="container appro-list flex mt30">
            <div class="meeting-report">
                <a-spin :spinning="loading" tip="Loading...">
                    <div class="interview-list" v-if="approiList.length">
                        <div v-for="(item,index) in approiList" :key="index" class="hot-topic-box"
                            :class="{'mt20': index != 0}">
                            <hot-topic-item path="/infomation-detail" :type="item.type" containerWidth="750px"
                                width="220px" :item="item" class="bb">
                            </hot-topic-item>
                        </div>
                    </div>
                    <a-empty style="margin-top:100px;" v-if="!approiList.length && !loading" />
                </a-spin>
                <div class="t-c mt30" v-if="approiCount > 0">
                    <a-pagination :total="approiCount" v-model:current="approiParams.page"
                        :page-size="approiParams.limit" show-quick-jumper @change="handlePageChangeEvent"
                        :hideOnSinglePage="true" />
                </div>
            </div>
            <div class="meeting-right">
                <layout-right :config="layoutRightConfig"></layout-right>
            </div>
        </div>
    </layout>
</template>

<script>
import layout from '@/components/layout/index';
import layoutRight from '@/components/layout/layout-right';
import hotTopicItem from '@/components/hot-topic-item.vue';
import storage from 'store'
export default {
    name: 'index',
    components: {
        layout,
        layoutRight,
        hotTopicItem
    },
    data() {
        return {
            approiParams: {
                type: 4,
                page: 1,
                limit: 7,
            },
            approiCount: 0,
            approiList: [],
            layoutRightConfig: {
                // 本月会议
                meeting: {
                    show: true, // 是否显示
                    title: '本月会议', // 标题
                    methods: 'get',
                    data: {
                        year: new Date().getFullYear(),
                        month: new Date().getMonth() + 1,
                        page: 1,
                        pageSize: 6,
                        is_now_year_meeting: 1,
                        subject_ids: storage.get('subjectIds') || ''
                    },   // 参数条件
                },
                // 推荐直播
                live: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '推荐直播', // 标题
                    data: {
                        page: 1,
                        limit: 3
                    },   // 参数条件
                },
                // 推荐视频
                video: {
                    show: true, // 是否显示
                    methods: 'get',
                    title: '最新回放', // 标题
                    data: {
                        page: 1,
                        PageSize: 4
                    },   // 参数条件
                },
            },
            loading: true,
        }
    },
    created() {
        this.getApproiList()
    },
    methods: {
        // 期刊约稿列表
        getApproiList() {
            this.loading = true
            this.request.post('GetNews', this.approiParams).then(res => {
                this.approiCount = parseInt(res.data.count)
                this.approiList = res.data.list
                this.loading = false
            })
        },
        // 切换页码
        handlePageChangeEvent(page) {
            window.scrollTo(0,0);
            this.approiParams.page = page
            this.getApproiList()
        },
    }
}
</script>
<style lang="less" scoped>
.filter-condition {
    background: #f2f4fa;
    overflow: hidden;
    height: 8px;

    &.active {
        height: 50px;
        line-height: 50px;
    }
}
.meeting-report {
    width: calc(66.66% - 30px);

    .interview-list {
        min-height: 500px;
    }
}
.meeting-right {
    width: 33.33%;
}
@media screen and (max-width:768px){
    .container{
        padding: 0 10px;
    }
    .appro-list{
        display: block;
        margin-top: 10px;
        .meeting-report{
            width: 100%;
        }
        .meeting-right{
            width: 100%;
            margin-top: 50px;
        }
    }
}
</style>
